export const ENDPOINTS = {
    AUTH: {
        LOGIN: 'admin/onboarding/login',
        VERIFY_OTP: 'admin/onboarding/otp/verify',
        RESEND_OTP: 'admin/onboarding/resend/otp',
        RESET_PASSWORD: 'admin/onboarding/password/reset',
        FORGOT_PASSWORD: 'admin/onboarding/password/forgot',
        CITY_ZIPCODES_LIST: 'admin/onboarding/city/zipcode/listing',
    },
    CLIENT_MANAGEMENT: {
        LISTING: 'admin/client/list',
        BLOCK_UNBLOCK: 'admin/client',
        BLOCK_UNBLOCK_REASON: 'admin/client/reasons/rejection/list',
        DETAIL: 'admin/client/basic/details',
        BOOKING_MADE: 'booking',
        BOOKING_MADE_DETAILS: 'booking/',
        EXPORT_TO_CSV: 'admin/client/export/csv',
    },
    PHOTOGRAPHER_MANAGEMENT: {
        LISTING: 'admin/vendor/list',
        DETAIL: 'admin/vendor/basic/details',
        PACKAGE_LISTING: 'admin/vendor/package/listing',
        VERIFY_VENDOR: 'admin/vendor/verify',
        BLOCK_UNBLOCK: 'admin/vendor',
        BLOCK_UNBLOCK_REASON: 'admin/client/reasons/rejection/list',
        MARK_TOP_PHOTOGRAPHER: 'admin/vendor/mark/photographer',
        ALBUM_DETAIL: 'admin/vendor/album/images',
        BOOKING_MADE: 'booking',
        BOOKING_MADE_DETAILS: 'booking/',
        EXPORT_TO_CSV: 'admin/vendor/export/csv',
    },
    CATEGORY_MANAGEMENT: {
        LISTING: 'admin/category',
        ADD_CATEGORY: 'admin/category',
        EDIT_CATEGORY: 'admin/category',
    },
    RATING_AND_REVIEW_MANAGEMENT: {
        LISTING: 'admin/rating-review',
        ADD_RATING_REVIEW: 'admin/rating-review',
        HIDE_RATING_REVIEW: 'admin/rating-review/hide',
        RATING_ON_OFF: 'admin/rating-review/feature',
        DETAIL: 'admin/rating-review/',
        DELETE: 'admin/rating-review',
    },
    EARN_AND_TRANSACTION_MANAGEMENT: {
        LISTING: 'booking/transaction',
        DETAIL: 'booking/',
        REPORTS: 'admin/reports-analytics/earnings/vs/transaction',
        EXPORT_TO_CSV: 'booking/export/csv',
    },
    REPORT_AND_ANALYTICS_MANAGEMENT: {
        CLIENT_PHOTOGRAPHER_REPORTS: 'admin/reports-analytics/active',
        MOST_POPULAR_CATEGORY_REPORTS: 'admin/reports-analytics/popular/category',
        INSTANT_SCHEDULE_REPORTS: 'admin/reports-analytics/earnings/vs/booking-type',
        TOP_ACTIVE_CLIENTS_REPORTS: 'admin/reports-analytics/active/clients',
        PHOTOGRAPHY_VS_VIDEOGRAPHY_REPORTS: 'admin/reports-analytics/earnings/vs/vendor',
        MOST_BOOKED_PHOTOGRAPHER_REPORTS: 'admin/reports-analytics/booked/photographer',
        MOST_BOOKED_LOCATION_REPORTS: 'admin/reports-analytics/booked/location',
        TOP_RATED_PHOTOGRAPHER_REPORTS: 'admin/reports-analytics/top/vendor',
        BOOKING_VS_TIPS_EARNING_REPORTS: 'admin/reports-analytics/earnings/vs/booking-type',
        TOTAL_BOOKING_VS_TIPS_REPORTS: 'admin/reports-analytics/earnings/vs/booking-type',
        AVERAGE_PHOTOSHOOT_COST_REPORTS: 'admin/reports-analytics/average/cost',
        AVERAGE_HOURLY_RATE_REPORTS: 'admin/reports-analytics/average/rate',
        MOST_BOOKED_PRICE_RANGE_REPORTS: 'admin/reports-analytics/booked/range',
        MOST_BOOKED_CATEGORY_REPORTS: 'admin/reports-analytics/popular/category',
        AVERAGE_PHOTOSHOOT_DURATION_REPORTS: 'admin/reports-analytics/average/duration',
    },
    CUSTOMER_SATISFACTION: {
        MANAGE_DISPUTE_LISTING: 'admin/customer-satisfaction/list',
        MANAGE_DISPUTE_DETAILS: 'admin/customer-satisfaction/details',
        UPDATE_DISPUTE_STATUS: 'admin/customer-satisfaction/status',
        SEND_WARNING_MAIL: 'admin/customer-satisfaction/warning-mail',
    },
    NOTIFICATION_MANAGEMENT: {
        LISTING: 'admin/notification/list',
        DELETE: 'admin/notification',
        RESEND: 'admin/notification/resend',
        DETAIL: 'admin/notification',
        ADD: 'admin/notification',
    },
    CONTENT_MANAGEMENT: {
        LISTING: 'admin/content',
        EDIT: 'admin/content',
    },
    PROFILE: {
        CHANGE_PASSWORD: 'admin/onboarding/password/change',
        UPDATE_PROFILE: 'admin/onboarding/profile',
    },
    DASHBOARD: {
        USER_COUNT: 'admin/dashboard/count',
    },
    COMMON: {
        FILE_UPLOAD_REQUEST: 'common/admin/file/upload/request',
    },
};
