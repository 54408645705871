const ROUTE_NAMES = {
    BASE_ROUTE: '/',

    AUTH_ROUTES: {
        TWO_FACTOR_AUTH: '/two-factor-authentication',
        FORGOT_PASS: '/forgot-password',
        RESET_PASS: '/reset-password',
        OTP_VERIFICATION: '/otp-verification',
    },

    MAIN_ROUTES: {
        DASHBOARD: '/dashboard',
        ACCOUNT_SETTINGS: '/account-settings',
        CLIENT_MANAGEMENT: '/client-management',
        CLIENT_MANAGEMENT_DETAIL: '/client-management/detail',
        CLIENT_MANAGEMENT_BOOKING_DETAIL: '/client-management/booking/detail',
        PHOTOGRAPHERS_MANAGEMENT: '/photographers-management',
        PHOTOGRAPHERS_MANAGEMENT_DETAIL: '/photographers-management/detail',
        RATING_AND_REVIEW_MANAGEMENT: '/rating-management',
        RATING_AND_REVIEW_MANAGEMENT_DETAIL: '/rating-management/detail',
        PHOTOGRAPHERS_MANAGEMENT_BOOKING_DETAIL: '/photographers-management/booking/detail',
        CATEGORY_MANAGEMENT: '/category-management',
        NOTIFICATIONS_MANAGEMENT: '/notifications-management',
        ADD_NOTIFICATIONS: '/notifications-management/add-notifications',
        VIEW_NOTIFICATIONS: '/notifications-management/notifications',
        REPORTS_AND_ANALYTICS: '/reports-and-analytics',
        CUSTOMER_SATISFACTION: '/customer-satisfaction',
        CUSTOMER_SATISFACTION_DETAIL: '/customer-satisfaction/detail',
        FLAGGED_ACCOUNT_DETAIL: '/customer-satisfaction/flagged-account/detail',
        TECHNICAL_ISSUE_DETAIL: '/customer-satisfaction/technical-issue/detail',
        FEEDBACK_DETAIL: '/customer-satisfaction/feedback/detail',
        CUSTOMER_SATISFACTION_CHAT: '/customer-satisfaction/dispute/chat',
        FLAGGED_ACCOUNT_CHAT: '/customer-satisfaction/flagged-account/chat',
        EARNINGS_TRANSACTIONS_MANAGEMENT: '/earnings-transactions-management',
        EARNINGS_TRANSACTIONS_MANAGEMENT_DETAIL: '/earnings-transactions-management/detail',
        CONTENT_MANAGEMENT: '/content-management',
    },
};

export default ROUTE_NAMES;
