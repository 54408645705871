//icons
import PG_MGT_ICON from 'assets/images/pg_mgt_icon.png';
import DASHBOARD_ICON from 'assets/images/dashboard_icon.png';
import CLIENT_MGT_ICON from 'assets/images/client_mgt_icon.png';
import ACCOUNT_SETTINGS_ICON from 'assets/images/account_settings_icon.png';
import CATEGORY_MANAGEMENT_ICON from 'assets/images/category_management_icon.png';
import RATING_REVIEW_MANAGEMENT_ICON from 'assets/images/review_rating.png';
import NOTIFICATIONS_MANAGEMENT_ICON from 'assets/images/notification.png';
import REPORTS_AND_ANALYTICS_ICON from 'assets/images/reports_and_analytics.png';
import CUSTOMER_SATISFACTION_ICON from 'assets/images/customer-satisfaction.png';
import EARNINGS_TRANSACTIONS_MANAGEMENT_ICON from 'assets/images/earnings.png';
import CONTENT_MANAGEMENT_ICON from 'assets/images/content.png';
import ROUTE_NAMES from 'routes/routeNames';

const SIDE_BAR_ITEMS = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        imgURL: DASHBOARD_ICON,
        url: ROUTE_NAMES.MAIN_ROUTES.DASHBOARD,
    },
    {
        imgURL: CLIENT_MGT_ICON,
        id: 'client-management',
        title: 'Client Management',
        url: ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT,
    },
    {
        imgURL: PG_MGT_ICON,
        id: 'photographers-management',
        title: 'Photographer Management',
        url: ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT,
    },
    {
        imgURL: CATEGORY_MANAGEMENT_ICON,
        id: 'category-management',
        title: 'Category Management',
        url: ROUTE_NAMES.MAIN_ROUTES.CATEGORY_MANAGEMENT,
    },
    {
        imgURL: EARNINGS_TRANSACTIONS_MANAGEMENT_ICON,
        id: 'earnings-transactions-management',
        title: 'Earnings & Transactions Management',
        url: ROUTE_NAMES.MAIN_ROUTES.EARNINGS_TRANSACTIONS_MANAGEMENT,
    },
    {
        imgURL: RATING_REVIEW_MANAGEMENT_ICON,
        id: 'rating-review-management',
        title: 'Review & Ratings Management',
        url: ROUTE_NAMES.MAIN_ROUTES.RATING_AND_REVIEW_MANAGEMENT,
    },
    {
        imgURL: REPORTS_AND_ANALYTICS_ICON,
        id: 'reports-and-analytics',
        title: 'Reports & Analytics',
        url: ROUTE_NAMES.MAIN_ROUTES.REPORTS_AND_ANALYTICS,
    },
    {
        imgURL: CUSTOMER_SATISFACTION_ICON,
        id: 'customer-satisfaction',
        title: 'Customer Satisfaction',
        url: ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION,
    },
    {
        id: 'notifications-management',
        title: 'Notifications Management',
        imgURL: NOTIFICATIONS_MANAGEMENT_ICON,
        url: ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT,
    },
    {
        id: 'content-management',
        title: 'Content Management',
        imgURL: CONTENT_MANAGEMENT_ICON,
        url: ROUTE_NAMES.MAIN_ROUTES.CONTENT_MANAGEMENT,
    },
    {
        id: 'account-settings',
        title: 'Account Settings',
        imgURL: ACCOUNT_SETTINGS_ICON,
        url: ROUTE_NAMES.MAIN_ROUTES.ACCOUNT_SETTINGS,
    },
];

export default SIDE_BAR_ITEMS;
